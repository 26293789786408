import { isnull } from ".";

export default {
	//根据code获取数组内对应的对象
	Query(value, list) {
		var data =
			list.filter((item) => item.code === value).length != 0 ? list.filter((item) => item.code === value)[0]
			.value ? list.filter((item) => item.code === value)[0].value : 0 : 0;
		return data;
	},
	//计算百分比
	GetPercent(big, small) {
		if (big != 0 && small != 0) {
			var num = parseInt((small / big) * 100) > 100 ? 100 : parseInt((small / big) * 100);
			return num;
		} else {
			return 0;
		}
	},
	GetOrderNo() {
		var time = new Date()
		let year = time.getFullYear();
		let month = time.getMonth() + 1;
		let day = time.getDate();
		var hour = time.getHours()
		var minute = time.getMinutes()
		var second = time.getSeconds()
		var millisecond = time.getTime()
		return year + '' + month + '' + day + '' + hour + '' + minute + '' + minute + '' + minute + '' + millisecond
	},
	// 时间格式化 2019-09-08
	timeFormat(date, type) {
		var time = new Date(date)
		let year = time.getFullYear();
		let month = time.getMonth() + 1;
		let day = time.getDate();
		if (type == 1) {
			return year + '年' + month + '月' + day + '日'
		} else if (type == 2) {
			return year + '/' + month + '/' + day
		} else if (type == 3) {
			return year + '-' + month
		} else if (type == 4) {
			return day
		} else {
			return year + '-' + month + '-' + day
		}

	},
	//时间字符串转yyyy-mm-dd hh:mm:ss
	stringToDateTime(str) {
		if (str == null || str.length < 19) return "";
		if (Number(str.substring(0, 4)) < 1949) return "";
		return (str).substring(0, 19).replace(/T/, " ");
	},

	//时间字符串转yyyy-mm-dd
	stringToDate(str) {
		if (str == null || str.length < 10) return "";
		if (Number(str.substring(0, 4)) < 1949) return "";
		return (str).substring(0, 10);
	},
	numK(mindata, maxdata) {
		var min = mindata ? this.convert(mindata) : 0
		var max = maxdata ? this.convert(maxdata) : 0
		if (min == 0 && max != 0) {
			return max + 'K'
		} else if (min != 0 && max == 0) {
			return min + 'K'
		} else if (min == 0 && max == 0) {
			return '面议'
		} else {
			return min + 'K-' + max + 'K'
		}

	},
	convert(data) {
		data = data / 1000;
		return data >= 1000 ?
			Number.isInteger(data) ?
			data :
			data.toFixed(1) :
			data;
	},

	// 计算时间差
	handleDate(timespan) {

		// timespan = timespan.replace(/-/g, "/");

		var dateTime = new Date(timespan) // 将传进来的字符串或者毫秒转为标准时间

		var year = dateTime.getFullYear()
		var month = dateTime.getMonth() + 1
		var day = dateTime.getDate()
		var hour = dateTime.getHours()
		var minute = dateTime.getMinutes()
		// var second = dateTime.getSeconds()
		var millisecond = dateTime.getTime() // 将当前编辑的时间转换为毫秒
		var now = new Date() // 获取本机当前的时间
		var nowNew = now.getTime() // 将本机的时间转换为毫秒
		var milliseconds = 0
		var timeSpanStr
		milliseconds = nowNew - millisecond
		if (milliseconds <= 1000 * 60 * 1) { // 小于一分钟展示为刚刚
			timeSpanStr = '刚刚'
		} else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) { // 大于一分钟小于一小时展示为分钟
			timeSpanStr = Math.round((milliseconds / (1000 * 60))) + '分钟前'
		} else if (1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) { // 大于一小时小于一天展示为小时
			timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + '小时前'
		} else if (1000 * 60 * 60 * 24 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 15) { // 大于一天小于十五天展示位天
			timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + '天前'
		} else if (milliseconds > 1000 * 60 * 60 * 24 * 15 && year === now.getFullYear()) {
			timeSpanStr = month + '-' + day + ' ' + hour + ':' + minute
		} else {
			timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute
		}
		return timeSpanStr
	},

	// 活跃
	handleDateHY(timespan) {

		// timespan = timespan.replace(/-/g, "/");

		var dateTime = new Date(timespan) // 将传进来的字符串或者毫秒转为标准时间

		var year = dateTime.getFullYear()

		var month = dateTime.getMonth() + 1
		var day = dateTime.getDate()
		var hour = dateTime.getHours()
		var minute = dateTime.getMinutes()
		// var second = dateTime.getSeconds()
		var millisecond = dateTime.getTime() // 将当前编辑的时间转换为毫秒
		var now = new Date() // 获取本机当前的时间
		var nowNew = now.getTime() // 将本机的时间转换为毫秒
		var milliseconds = 0
		var timeSpanStr
		milliseconds = nowNew - millisecond


		if (milliseconds <= 1000 * 60 * 1) { // 小于一分钟展示为刚刚
			timeSpanStr = '刚刚活跃'
		} else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) { // 大于一分钟小于一小时展示为分钟
			// timeSpanStr = Math.round((milliseconds / (1000 * 60))) + '分钟前'
			timeSpanStr = '刚刚活跃'
		} else if (1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) { // 大于一小时小于一天展示为小时
			// timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + '小时前'
			timeSpanStr = '今天活跃'
		} else if (1000 * 60 * 60 * 24 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 7) { // 大于一天小于十五天展示位天
			// timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + '天前'
			timeSpanStr = '本周活跃'
		} else if (1000 * 60 * 60 * 24 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 30) { // 大于一天小于十五天展示位天
			// timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + '天前'
			timeSpanStr = '本月活跃'
		} else if (milliseconds < 1000 * 60 * 60 * 24 * 180) {
			// timeSpanStr = month + '-' + day + ' ' + hour + ':' + minute
			timeSpanStr = '半年内活跃'
		} else if (milliseconds > 1000 * 60 * 60 * 24 * 180 && year === now.getFullYear()) {
			// timeSpanStr = month + '-' + day + ' ' + hour + ':' + minute
			timeSpanStr = '半年前活跃'
		} else if (year < 2015) {
			timeSpanStr = '长期离线'
		} else {
			// timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute
			timeSpanStr = '最后活跃:' + year + '-' + month + '-' + day
		}

		return timeSpanStr

	},
	handleDateHYD(timespan, type) {

		// timespan = timespan.replace(/-/g, "/");

		var dateTime = new Date(timespan) // 将传进来的字符串或者毫秒转为标准时间

		var year = dateTime.getFullYear()

		var month = dateTime.getMonth() + 1
		var day = dateTime.getDate()
		var hour = dateTime.getHours()
		var minute = dateTime.getMinutes()
		// var second = dateTime.getSeconds()
		var millisecond = dateTime.getTime() // 将当前编辑的时间转换为毫秒
		var now = new Date() // 获取本机当前的时间
		var nowNew = now.getTime() // 将本机的时间转换为毫秒
		var milliseconds = 0
		var timeSpanStr
		milliseconds = nowNew - millisecond


		if (milliseconds <= 1000 * 60 * 1) { // 小于一分钟展示为刚刚
			timeSpanStr = type ? '刚刚' + type : '刚刚活跃'
		} else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) { // 大于一分钟小于一小时展示为分钟
			// timeSpanStr = Math.round((milliseconds / (1000 * 60))) + '分钟前'
			timeSpanStr = type ? '刚刚' + type : '刚刚活跃'
		} else if (1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) { // 大于一小时小于一天展示为小时
			// timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + '小时前'
			timeSpanStr = type ? '今天' + type : '今天活跃'
		} else {
			// timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute
			timeSpanStr = type ? '近期' + type : '近期活跃'
		}

		return timeSpanStr

	},
	cd(t1, t2, tg) {
		//相差的毫秒数
		var ms = Date.parse(t1) - Date.parse(t2);
		var minutes = 1000 * 60;
		var hours = minutes * 60;
		var days = hours * 24;
		var years = days * 365;
		//求出天数
		var d = Math.floor(ms / days);
		//求出除开天数，剩余的毫秒数
		ms %= days;
		var h = Math.floor(ms / hours);
		ms %= hours;
		var m = Math.floor(ms / minutes);
		ms %= minutes;
		var s = Math.floor(ms / 1000);
		//返回所需值并退出函数
		switch (tg) {
			case 'd':
				return d;
			case 'h':
				return h;
			case 'm':
				return m;
			case 's':
				return s;
		}
	},
	//号码验证
	telVerify: function(phone) {

		let message = '';
		let state = '';
		if (phone) {
			if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone)) {
				message = '';
				state = true;
			} else {
				message = '您输入的手机号码有误';
				state = false;
			}
		} else {
			message = '输入的手机号不能为空',
				state = false
		}
		return {
			message: message,
			state: state,
			data: phone
		};

	},
	//身份证格式验证
	idCardVerify: function(idCard) {

		let message = '';
		let state = true;
		if (idCard.length == 15) {
			if (this.isValidityBrithBy15IdCard(idCard)) {
				message = '';
				state = true;
			} else {
				message = '您输入的身份证有误';
				state = false;
			}
		} else if (idCard.length == 18) {
			var a_idCard = idCard.split(""); // 得到身份证数组
			if (this.isValidityBrithBy18IdCard(idCard) && this.isTrueValidateCodeBy18IdCard(
					a_idCard)) { //进行18位身份证的基本验证和第18位的验证
				message = '';
				state = true;
			} else {
				message = '您输入的身份证有误';
				state = false;
			}
		} else {
			if (idCard.length === 0) {
				message = '输入的身份证号不能为空';
				state = false;
			} else {
				message = '您输入的身份证长度有误';
				state = false;
			}
		}
		return {
			message: message,
			state: state,
			data: idCard
		};

	},
	//号码脱敏
	phone(str) {
		return str.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
	},
	//身份证脱敏
	idcard(engineno) {
		return engineno.replace(/^(.{2})(?:\w+)(.{2})$/, "\$1****\$2");
	}, //银行卡脱敏
	BankCardNo(accountNo) {
		// return '******' + accountNo.substr(accountNo.length - 4)
		return accountNo.replace(/(\d{0})\d*(\d{4})/, '$1**** $2');
	},
	//姓名脱敏
	formatName(name) {
		var newStr;
		if (name.length === 2) {
			newStr = name.substr(0, 1) + '*';
		} else if (name.length > 2) {
			var char = '';
			for (var i = 0, len = name.length - 2; i < len; i++) {
				char += '*';
			}
			newStr = name.substr(0, 1) + char + name.substr(-1, 1);
		} else {
			newStr = name;
		}
		return newStr;
	},
	//计算年龄
	getAge(strAge) {
		if(isnull(strAge)) return false;
		var birArr = strAge.split("-");
		var birYear = birArr[0];
		var birMonth = birArr[1];
		var birDay = birArr[2];

		d = new Date();
		var nowYear = d.getFullYear();
		var nowMonth = d.getMonth() + 1; //记得加1
		var nowDay = d.getDate();
		var returnAge;

		if (birArr == null) {
			return false
		};
		var d = new Date(birYear, birMonth - 1, birDay);
		if (d.getFullYear() == birYear && (d.getMonth() + 1) == birMonth && d.getDate() == birDay) {
			if (nowYear == birYear) {
				returnAge = 0; // 
			} else {
				var ageDiff = nowYear - birYear; // 
				if (ageDiff > 0) {
					if (nowMonth == birMonth) {
						var dayDiff = nowDay - birDay; // 
						if (dayDiff < 0) {
							returnAge = ageDiff - 1;
						} else {
							returnAge = ageDiff;
						}
					} else {
						var monthDiff = nowMonth - birMonth; // 
						if (monthDiff < 0) {
							returnAge = ageDiff - 1;
						} else {
							returnAge = ageDiff;
						}
					}
				} else {
					return "出生日期晚于今天，数据有误"; //返回-1 表示出生日期输入错误 晚于今天
				}
			}
			return returnAge;
		} else {
			return ("输入的日期格式错误！");
		}
	},
	//15位身份证
	isValidityBrithBy15IdCard: function(idCard15) {
		var year = idCard15.substring(6, 8);
		var month = idCard15.substring(8, 10);
		var day = idCard15.substring(10, 12);
		var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
		// 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
		if (temp_date.getYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date
			.getDate() != parseFloat(day)) {
			return false;
		} else {
			return true;
		}
	},
	//18位生日校验
	isValidityBrithBy18IdCard: function(idCard18) {
		var year = idCard18.substring(6, 10);
		var month = idCard18.substring(10, 12);
		var day = idCard18.substring(12, 14);
		var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
		// 这里用getFullYear()获取年份，避免千年虫问题
		if (temp_date.getFullYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 ||
			temp_date.getDate() != parseFloat(day)) {
			return false;
		} else {
			return true;
		}
	},
	//18位最后一位校验
	isTrueValidateCodeBy18IdCard: function(a_idCard) {
		var Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1]; // 加权因子
		var ValideCode = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2]; // 身份证验证位值.10代表X
		var sum = 0; // 声明加权求和变量
		if (a_idCard[17].toLowerCase() == 'x') {
			a_idCard[17] = 10; // 将最后位为x的验证码替换为10方便后续操作
		}
		for (var i = 0; i < 17; i++) {
			sum += Wi[i] * a_idCard[i]; // 加权求和
		}
		var valCodePosition = sum % 11; // 得到验证码所位置
		if (a_idCard[17] == ValideCode[valCodePosition]) {
			return true;
		} else {
			return false;
		}
	},
	nowInDateBetwen(d1, d2) {
		//如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
		var dateEnd = new Date(d2.replace(/-/g, "/")); //将-转化为/，使用new Date
		// var dateNow = new Date(date.replace(/-/g, "/"));//将-转化为/，使用new Date
		//var dateBegin = new Date(d1);//将-转化为/，使用new Date
		//var dateEnd = new Date(d2);//将-转化为/，使用new Date
		var dateNow = new Date(); //获取当前时间
		if (d1) {
			var dateBegin = new Date(d1.replace(/-/g, "/")); //将-转化为/，使用new Date
			var beginDiff = dateNow.getTime() - dateBegin.getTime(); //时间差的毫秒数
			var beginDayDiff = Math.floor(beginDiff / (24 * 3600 * 1000)); //计算出相差天数
		}

		if (d2) {
			var dateEnd = new Date(d2.replace(/-/g, "/")); //将-转化为/，使用new Date
			var endDiff = dateEnd.getTime() - dateNow.getTime(); //时间差的毫秒数
			var endDayDiff = Math.floor(endDiff / (24 * 3600 * 1000)); //计算出相差天数
		}
		if (d1 && beginDayDiff < 0) { //没到开始时间
			return 1;
		}
		if (d2 && endDayDiff < 0) { //已过期
			return 2
		}
		if (d1 && beginDayDiff == 0) {
			return 3;
		}
		if (d2 && endDayDiff == 0) {
			return 4
		}

		return 5;
	},

	// 判断是否在时间区间内
	isDuringTime(d1, d2) {
		//如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
		var dateEnd = new Date(d2.replace(/-/g, "/")); //将-转化为/，使用new Date
		// var dateNow = new Date(date.replace(/-/g, "/"));//将-转化为/，使用new Date
		//var dateBegin = new Date(d1);//将-转化为/，使用new Date
		//var dateEnd = new Date(d2);//将-转化为/，使用new Date
		var dateNow = new Date(); //获取当前时间
		if (d1) {
			var dateBegin = new Date(d1.replace(/-/g, "/")); //将-转化为/，使用new Date
			var beginDiff = dateNow.getTime() - dateBegin.getTime(); //时间差的毫秒数
		}

		if (d2) {
			var dateEnd = new Date(d2.replace(/-/g, "/")); //将-转化为/，使用new Date
			var endDiff = dateEnd.getTime() - dateNow.getTime(); //时间差的毫秒数
		}
		// 在此期间
		if (d1 && d2 && beginDiff > 0 && endDiff > 0) {
			return 1
		} 
		// 未开始
		if (d1 && d2 && beginDiff < 0) {
			return 2
		}
		// 已结束
		if (d1 && d2 && endDiff < 0) {
			return 3
		}
		return 0
	},
	isDuringDate: function(beginDateStr, endDateStr) {
		var curDate = new Date(),
			beginDate = new Date(beginDateStr),
			endDate = new Date(endDateStr);
		if (curDate >= beginDate && curDate <= endDate) {
			return true;
		}
		return false;
	},
	dateChange: function(num = 1, date = false) {
		if (!date) {
			date = new Date(); //没有传入值时,默认是当前日期
			date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
		}
		date += " 00:00:00"; //设置为当天凌晨12点
		date = Date.parse(new Date(date)) / 1000; //转换为时间戳
		date += (86400) * num; //修改后的时间戳
		var newDate = new Date(parseInt(date) * 1000); //转换为时间
		return newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate();
	},
	CharMode: function(iN) {
		if (iN >= 48 && iN <= 57) //数字
			return 1;
		if (iN >= 65 && iN <= 90) //大写字母
			return 2;
		if (iN >= 97 && iN <= 122) //小写
			return 4;
		else
			return 8; //特殊字符
	},
	//bitTotal函数
	//计算出当前密码当中一共有多少种模式
	bitTotal: function(num) {
		var modes = 0;
		for (let i = 0; i < 4; i++) {
			if (num & 1) modes++;
			num >>>= 1;
		}
		return modes;
	},
	//checkStrong函数
	//返回密码的强度级别
	checkStrong: function(sPW) {
		if (sPW.length <= 4)
			return 0; //密码太短
		var Modes = 0;
		for (let i = 0; i < sPW.length; i++) {
			//测试每一个字符的类别并统计一共有多少种模式.
			Modes |= this.CharMode(sPW.charCodeAt(i));
		}
		return this.bitTotal(Modes);
	},
	//pwStrength函数
	//当用户放开键盘或密码输入框失去焦点时,根据不同的级别显示不同的颜色
	pwStrength: function(pwd) {
		var O_color = "#eeeeee";
		var L_color = "#FF0000";
		var M_color = "#FF9900";
		var H_color = "#33CC00";
		var Lcolor;
		var Mcolor;
		var Hcolor;
		if (pwd == null || pwd == '') {
			Lcolor = Mcolor = Hcolor = O_color;
		} else {
			var S_level = this.checkStrong(pwd);
			switch (S_level) {
				case 0:
					Lcolor = Mcolor = Hcolor = O_color;
				case 1:
					Lcolor = L_color;
					Mcolor = Hcolor = O_color;
					break;
				case 2:
					Lcolor = Mcolor = M_color;
					Hcolor = O_color;
					break;
				default:
					Lcolor = Mcolor = Hcolor = H_color;
			}
		}
		document.getElementById("strength_L").style.background = Lcolor;
		document.getElementById("strength_M").style.background = Mcolor;
		document.getElementById("strength_H").style.background = Hcolor;
		return;
	},
	// 比较两个时间差，true:后一个时间大于前一个时间
	compareTime: function(t1, t2) {
		// 通过打印截止时间判断是否已经招考，从而判断是否显示
		var dateStart = new Date(t1.replace(/-/g, "/"));
		var dateEnd = new Date(t2.replace(/-/g, "/"));
		var dateDiff = dateEnd.getTime() - dateStart.getTime();
		if (dateDiff < 0) {
			return false;
		}
		return true;
	},
}
