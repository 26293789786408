var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"header"},[_c('div',{staticClass:"header-contain"},[_c('div',{staticClass:"logo",on:{"click":function($event){return _vm.goto('/home', '')}}},[_c('img',{attrs:{"src":require("@/assets/img/logo.png"),"alt":""}})]),_c('div',{staticClass:"header-nav"},[_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,staticClass:"nav-item",class:[
            index === _vm.nowIndex ? 'tab-active' : '',
            index === _vm.activeIndex ? 'tab-active' : '',
          ],on:{"mouseover":function($event){return _vm.activeTab(index)},"mouseout":_vm.moveActive,"click":function($event){return _vm.changeTab(item, index, $event)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),_c('div',{attrs:{"id":"header-blue-block"}})],2),_c('div',{staticClass:"user-nav"},[(_vm.userinfo === null)?_c('div',{staticClass:"follow_wechat",staticStyle:{"margin-left":"20px"}},[_c('img',{attrs:{"src":require("../assets/img/login_user.png")}}),_c('router-link',{attrs:{"to":"/login"}},[_vm._v(" 个人登录 ")]),_vm._v(" / "),_c('router-link',{attrs:{"to":"/register"}},[_vm._v(" 注册 ")])],1):_vm._e(),(_vm.userinfo === null)?_c('div',{staticClass:"follow_wechat",staticStyle:{"margin-left":"20px"}},[_c('img',{attrs:{"src":require("../assets/img/login_com.png")}}),_c('router-link',{attrs:{"to":"/loginc"}},[_vm._v(" 企业登录 ")]),_vm._v(" / "),_c('router-link',{attrs:{"to":"/registerc"}},[_vm._v(" 注册 ")])],1):_vm._e(),(_vm.userinfo != null)?_c('div',{staticClass:"user-nav-item user-nav-item1"},[_c('span',{staticClass:"user-name",attrs:{"title":_vm.userinfo.userType + '' === '1'
				                  ? _vm.userinfo.peR_NAME
				                    ? _vm.userinfo.peR_NAME
				                    : _vm.userinfo.peR_ACCOUNT
				                  : _vm.userinfo.coM_NAME
				                  ? _vm.userinfo.coM_NAME
				                  : _vm.userinfo.coM_ACCOUNT}},[_vm._v("【"+_vm._s(_vm.userinfo.userType + "" === "1" ? _vm.userinfo.peR_NAME ? _vm.userinfo.peR_NAME : _vm.userinfo.peR_ACCOUNT : _vm.userinfo.coM_NAME ? _vm.userinfo.coM_NAME : _vm.userinfo.coM_ACCOUNT)+"】")]),_c('router-link',{attrs:{"to":`/${
				              _vm.userinfo.userType === 1 ? 'usercenter' : 'companycenter'
				            }?type=0`}},[_c('div',{staticClass:"news badge",attrs:{"data-newsnum":this.messageCount}},[_c('span',{staticClass:"blue"},[_vm._v(_vm._s(_vm.userinfo.userType + "" === "1" ? "[个人中心]" : "[企业中心]"))])])]),_c('el-dropdown',{on:{"command":_vm.handleCommand}},[_c('img',{attrs:{"src":_vm.setcompanylogo(_vm.userinfo.peR_IMG_PATH),"alt":""}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"loingout"}},[_vm._v("退出")])],1)],1)],1):_vm._e(),_c('el-dropdown',[_c('img',{attrs:{"src":require("@/assets/img/kf.png")}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[_vm._v("0579-89010200")])],1)],1)],1),_c('div',{staticClass:"hot-tel"}),_c('div',{staticClass:"login-user"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }