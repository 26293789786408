import Index from '@/views/index'
import Index1 from '@/views/index1'
import Home from '@/views/Home'

const routes = [{
		path: '/',
		redirect: 'home'
	},
	{
		path: '/jobhunting',
		name: 'jobhunting',
		component: () => import('@/views/job/jobhunting.vue'),
	},
	{
		path: '/findEnterprise',
		name: 'findEnterprise',
		component: () => import('@/views/enterprise/findEnterprise.vue'),
	},
	{
		path: '/jobfairdetail',
		name: 'jobfairdetail',
		component: () => import('@/views/jobfair/jobfairdetail.vue'),
	},
	{
		path: '/Ticket',
		name: 'Ticket',
		component: () => import('@/views/zhaokaobm/Ticket.vue'),
	},
	{
		path: '/',
		name: 'index',
		component: Index,
		children: [{
				path: '',
				name: 'Home',
				component: Home,
				meta: {
					keepalive: true
				},
			},
			{
				path: '/home',
				name: 'Home',
				component: Home,
			},
		]
	}, {
		path: '/',
		name: 'index1',
		component: Index1,
		children: [{
				path: '/register',
				name: 'register',
				component: () => import('@/views/register.vue'),
			},
			{
				path: '/login',
				name: 'login',
				component: () => import('@/views/login.vue'),
			},
			{
				path: '/loginc',
				name: 'loginc',
				component: () => import('@/views/loginc.vue'),
			},
			{
				path: '/registerc',
				name: 'registerc',
				component: () => import('@/views/registerc.vue'),
			},
			{
				path: '/resume',
				name: 'resume',
				component: () => import('@/views/resume/resume.vue'),
			},
			{
				path: '/jobdetail',
				name: 'jobdetail',
				component: () => import('@/views/job/jobdetail.vue'),
			},
			{
				path: '/resumedetail',
				name: 'resumedetail',
				component: () => import('@/views/companycenter/resumedetail.vue'),
			}, {
				path: '/companycenter',
				name: 'companycenter',
				component: () => import('@/views/companycenter/index.vue'),
			},
			{
				path: '/jobhunting1',
				name: 'jobhunting1',
				component: () => import('@/views/job/jobhunting1.vue'),
			},

			//企业详情
			{
				path: '/company',
				name: 'company',
				component: () => import('@/views/company/index.vue'),
			},
			//企业详情
			{
				path: '/company1',
				name: 'company1',
				component: () => import('@/views/company/index1.vue'),
			},
			// 企业列表
			// {
			// 	path: '/companyList',
			// 	name: 'companyList',
			// 	component: () => import('@/views/company/companyList.vue'),
			// },
			{
				path: '/recommend',
				name: 'recommend',
				component: () => import('@/views/company/recommend.vue'),
			},
			{
				path: '/jobfair',
				name: 'jobfair',
				component: () => import('@/views/jobfair/index.vue'),
			},
			{
				path: '/special',
				name: 'special',
				component: () => import('@/views/jobfair/special.vue'),
			},
			{
				path: '/school',
				name: 'school',
				component: () => import('@/views/school/index.vue'),
			},
			{
				path: '/usercenter',
				name: 'usercenter',
				component: () => import('@/views/userCenter/index.vue'),
			},
			{
				path: '/talentService',
				name: 'talentService',
				component: () => import('@/views/service/index.vue'),
			},
			{
				path: '/Eservice',
				name: 'Eservice',
				component: () => import('@/views/service/service.vue'),
			},
			{
				path: '/media',
				name: 'media',
				component: () => import('@/views/media/index.vue'),
			},
			{
				path: '/information',
				name: 'information',
				component: () => import('@/views/information/index.vue'),
			},
			{
				path: '/qyInformation',
				name: 'qyInformation',
				component: () => import('@/views/information/information.vue'),
			},
			{
				path: '/finish',
				name: 'finish',
				component: () => import('@/views/information/finish.vue'),
			},
			{
				path: '/news',
				name: 'news',
				component: () => import('@/views/news/index.vue'),
			}, {
				path: '/info',
				name: 'info',
				component: () => import('@/views/news/info.vue'),
			},

			{
				path: '/jobfairPostdetail',
				name: 'jobfairPostdetail',
				component: () => import('@/views/jobfair/jobfairPostdetail.vue'),
			},
			// 企业培训
			{
				path: '/trainingEmployment',
				name: 'trainingEmployment',
				component: () => import('@/views/trainingEmployment/index.vue'),
			},
			// 企业培训详情
			{
				path: '/trainingDetails',
				name: 'trainingDetails',
				component: () => import('@/views/trainingEmployment/trainingDetails.vue'),
			},
			{
				path: '/registrationUserInformation',
				name: 'registrationUserInformation',
				component: () => import('@/views/trainingEmployment/registrationUserInformation.vue'),
			},
			{
				path: '/confirm',
				name: 'confirm',
				component: () => import('@/views/trainingEmployment/confirm.vue'),
			},
			{
				path: '/orderPayment',
				name: 'orderPayment',
				component: () => import('@/views/trainingEmployment/orderPayment.vue'),
			},
			{
				path: '/exam',
				name: 'exam',
				component: () => import('@/views/exam/index.vue'),
			},
			{
				path: '/signUp',
				name: 'signUp',
				component: () => import('@/views/exam/signup.vue'),
			},
			{
				path: '/zhaokaobm',
				name: 'zhaokaobm',
				component: () =>
					import('@/views/zhaokaobm/index.vue'),
			}, {
				path: '/zhaokaoPay',
				name: 'zhaokaoPay',
				component: () =>
					import('@/views/zhaokaobm/zhaokaoPay.vue'),
			}, {
				path: '/zhaokaoTicket',
				name: 'zhaokaoTicket',
				component: () =>
					import('@/views/zhaokaobm/zhaokaoTicket.vue'),
			}, {
				path: '/zhaokaosumbit',
				name: 'zhaokaosumbit',
				component: () =>
					import('@/views/zhaokaobm/bm.vue'),
			},
			{
				path: '/zhaokaosubmit1',
				name: 'zhaokaosubmit1',
				component: () =>
					import('@/views/zhaokaobm/submitStep1.vue'),
			},
			{
				path: '/zhaokaoInfo',
				name: 'zhaokaoInfo',
				component: () =>
					import('@/views/zhaokaobm/info.vue'),
			},
			{
				path: '/bmComplate',
				name: 'bmComplate',
				component: () =>
					import('@/views/zhaokaobm/bmComplate.vue'),
			},
			{
				path: '/paybm',
				name: 'paybm',
				component: () =>
					import('@/views/zhaokaobm/paybm.vue'),
			},
		]
	}
]
export default routes
