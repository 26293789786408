import axios from "axios";
import globalconfig from "@/assets/js/globalconfig";

/****企业中心：创建/更新企业信息 { COM_ID:公司ID，COM_NAME：公司名，COM_LINKMAN：联系人，COM_ZJLB：证件类别，COM_ZJLB_CODE：证件编号，COM_MPHONE：手机号码，COM_PHONE：联系电话，COM_ZIPCODE：邮编，COM_NATURE：公司性质，COM_SCALE：公司规模，COM_WEBSITE：公司网址，COM_INDUSTRY：所属行业，COM_EMAIL：公司邮箱，COM_HYTYPE：招聘大类，COM_VOUCHER：营业执照，COM_INTRODUCT：公司介绍，}****/
export const opercompanyinfo = params => {
    var url = globalconfig.baseurl + "/apipccompany/opercompanyinfo";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****企业中心：人才推荐 { id: 公司ID,PageSize,PageNumber}****/
export const rencaituijian = params => {
    var url = globalconfig.baseurl + "/apipccompany/rencaituijian";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****企业中心：最新活跃 { id: 公司ID,PageSize,PageNumber}****/
export const lastestact = params => {
    var url = globalconfig.baseurl + "/apipccompany/lastestact";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****企业中心：公司收藏用户 { id: 公司ID,uid：用户ID}****/
export const favuser = params => {
    var url = globalconfig.baseurl + "/apipccompany/favuser";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****企业中心：公司取消收藏用户 { id: 公司ID,uid：用户ID}****/
export const cancelfavuser = params => {
    var url = globalconfig.baseurl + "/apipccompany/cancelfavuser";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****企业中心：企业投递信息 { id: 公司ID}****/
export const resumefavreport = params => {
    var url = globalconfig.baseurl + "/apipccompany/resumefavreport";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****企业中心：岗位列表 { id: 公司ID}****/
export const posilist = params => {
    var url = globalconfig.baseurl + "/apipccompany/posilist";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****企业中心：移除岗位 { id: 公司ID，cid：岗位ID}****/
export const delposi = params => {
    var url = globalconfig.baseurl + "/apipccompany/delposi";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****企业中心：岗位新增/编辑 { OF_COM_ID: 公司ID，OF_ID：岗位ID(为0即为新增)，OF_POSI_NAME：岗位名称，OF_CONTAINS：不限、应届毕业生、社精英（传中文），OF_POSI_TYPE：职位类型，OF_INVITE_NUM：招聘人数，OF_POSI_NATURE：岗位性质，OF_DEADLINE：有效期，OF_ZPBM：招聘部门（中文），OF_PRO_CODE：工作所在省，OF_CT_CODE：工作所在市，OF_WORKAREA：工作地区，OF_SALARY：薪资起始，OF_SALARYE：薪资结束，OF_MAJOR_REQ：所学专业，OF_GZJY：工作经验，OF_EDU_REQ：学历(中文)，OF_SEX：性别（中文，男，女，不限），OF_AGE_REQ：年龄，OF_FL：福利待遇，OF_POSI_DESC：详细描述，OF_SALARY_MY：是否工资面议（1.面议，0.不面议），OF_FLZS：福利指数（数字），OF_FL：福利指数内容（传id，多个id用英文,隔开），OF_FLZL_QT：其他福利指数}****/
export const operposi = params => {
    var url = globalconfig.baseurl + "/apipccompany/operposi";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****获取各类下拉参数 { types:行业、证件类别、公司规模、公司性质、招聘类别、招聘期限、民族、学历、薪资、技术职称、图文广告}****/
export const posilistall = params => {
    var url = globalconfig.baseurl + "/apipc/posilistall";
    return axios.post(url, params, {
        headers: {

        }
    });
};
/****企业详情：详情页{id}****/
export const companydetail = params => {
    var url = globalconfig.baseurl + "/apipc/companydetail";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****福利指数 默认基础值是6.0****/
export const flzs = params => {
    var url = globalconfig.baseurl + "/apipccompany/flzs";
    return axios.post(url, params, {

    });
};
/****查看用户简历：{id：简历ID}****/
export const getresumebyid = params => {
    var url = globalconfig.baseurl + "/apipc/getresumebyidch";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****招聘会管理：招聘会预告{PageSize,PageNumber}****/
export const jobfairprev = params => {
    var url = globalconfig.baseurl + "/apipc/jobfairprev";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****招聘会管理：已参加招聘会{PageSize,PageNumber}****/
export const jobfaired = params => {
    var url = globalconfig.baseurl + "/apipccompany/jobfaired";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****招聘会管理：招聘会详情（线上线下均走该接口）{id：招聘会ID， PageSize,PageNumber}****/
export const jobfairdetailbycompany = params => {
    var url = globalconfig.baseurl + "/apipccompany/jobfairdetailbycompany";
    return axios.post(url, params, {

    });
};
/****招聘会管理：线上单位填写(保存) {id：公司ID，uid：招聘会ID， startTime：直播开始时间，endTime：直播结束时间， PageSize,PageNumber}****/
export const onlinejobfaircompany = params => {
    var url = globalconfig.baseurl + "/apipccompany/onlinejobfaircompany";
    return axios.post(url, params, {

    });
};
/****招聘会管理：线下单位填写(保存) {id：公司ID，uid：招聘会ID， }****/
export const offlinejobfaircompany = params => {
    var url = globalconfig.baseurl + "/apipccompany/offlinejobfaircompany";
    return axios.post(url, params, {

    });
};

/****招聘会管理：获取线上单位信息 {id：公司ID，uid：招聘会ID，  PageSize,PageNumber}****/
export const getonlinejobfaircompany = params => {
    var url = globalconfig.baseurl + "/apipccompany/getonlinejobfaircompany";
    return axios.post(url, params, {

    });
};

/****招聘会管理：获取线下单位信息 {id：公司ID，uid：招聘会ID，}****/
export const getofflinejobfaircompany = params => {
    var url = globalconfig.baseurl + "/apipccompany/isnullzphcompany";
    return axios.post(url, params, {

    });
};
/****招聘会管理：获取线下单位信息修改/新增 ****/
export const editzphcompany = params => {
    var url = globalconfig.baseurl + "/apipccompany/editzphcompany";
    return axios.post(url, params, {

    });
};
/****招聘会管理：线上/线下招聘岗位列表 {id：公司ID，uid：招聘会ID， }****/
export const onlineposicompanylist = params => {
    var url = globalconfig.baseurl + "/apipc/onlineposicompanylist";
    return axios.post(url, params, {

    });
};

/****招聘会管理：线上/线下招聘岗位(删除) {id：公司ID，uid：招聘会ID，zid：岗位ID}****/
export const delonlineposicompany = params => {
    var url = globalconfig.baseurl + "/apipccompany/delonlineposicompany";
    return axios.post(url, params, {

    });
};
/****招聘会管理：线上/线下招聘岗位(新增/编辑) {id：岗位id（如果是新增，id设置为0），zphid：招聘会ID，iComid：公司ID，Zwmc：岗位名，cHy：招聘会职位类别（传code_value），cZprs：招聘人数，cDyb：起始薪资，cDye：结束薪资，cMy：薪资面议（面议=1，不面议=0），cGwyq：岗位描述，cHbys：毕业生岗位（应届毕业生=1，非应届毕业生=0），cXl：学历（传code_value），cJZ：招聘会紧缺度（传code_value），cZy：专业要求   ####用到的code_value查看该控制器的最下方接口}****/
export const onlineposicompany = params => {
    var url = globalconfig.baseurl + "/apipccompany/onlineposicompany";
    return axios.post(url, params, {

    });
};

/****招聘会管理：通过类别获取下拉菜单 { field：类别：分别为 招聘会职位类别，招聘会学历，招聘会紧缺度 ，传中文 }****/
export const hy = params => {
    var url = globalconfig.baseurl + "/apipccompany/hy";
    return axios.post(url, params, {

    });
};
/****招聘会管理：线上招聘信息确认 {id：公司ID，uid：招聘会ID， }****/
export const confirmonlinecompany = params => {
    var url = globalconfig.baseurl + "/apipccompany/confirmonlinecompany";
    return axios.post(url, params, {

    });
};
/****招聘会管理：线上招聘信息确认页面获取状态 {id：公司ID，uid：招聘会ID， }****/
export const confirmonlinecompanystatus = params => {
    var url = globalconfig.baseurl + "/apipccompany/confirmonlinecompanystatus";
    return axios.post(url, params, {

    });
};

/****招聘会管理：线下招聘信息确认页面获取状态 {id：公司ID，uid：招聘会ID}****/
export const confirmoffline = params => {
    var url = globalconfig.baseurl + "/apipccompany/confirmoffline";
    return axios.post(url, params, {

    });
};
/****招聘会管理：线下招聘信息确认 {id：公司ID，uid：招聘会ID}****/
export const confirmzphcompany = params => {
    var url = globalconfig.baseurl + "/apipccompany/confirmzphcompany";
    return axios.post(url, params, {

    });
};
/****招聘会管理：获取线下展位信息 {id：公司ID，uid：招聘会ID，}****/
export const offlinezanw = params => {
    var url = globalconfig.baseurl + "/apipccompany/offlinezanw";
    return axios.post(url, params, {

    });
};
/****招聘会管理：确定线下展位信息 {id：公司ID，uid：招聘会ID，zid：展位ID，选择多个展位ID用英文,隔开}****/
export const offlinezanwid = params => {
    var url = globalconfig.baseurl + "/apipccompany/offlinezanwid";
    return axios.post(url, params, {

    });
};
/****简历数****/
export const getpersonnum = params => {
    var url = globalconfig.baseurl + "/apipccompany/getpersonnum";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****简历列表****/
export const getpersonpage = params => {
    var url = globalconfig.baseurl + "/apipccompany/getpersonpage";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****找人才简历列表****/
export const searchperson = params => {
    var url = globalconfig.baseurl + "/apipc/searchperson";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****收到的投递简历列表****/
export const GetPerJobByComId = params => {
    var url = globalconfig.baseurl + "/apipc/GetPerJobByComId";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};
/****已收藏的简历列表****/
export const GetComCollByComId = params => {
    var url = globalconfig.baseurl + "/apipc/GetComCollByComId";
    return axios.post(url, params, {
        headers: {
            'showLoadType': params.showLoadType
        }
    });
};

/****未读消息  {id：发送人PersonId , types：0是文字，1是岗位，2是企业，3是视频聊天}****/
export const IsReadMessage = params => {
    var url = globalconfig.baseurl + "/APIPC/IsReadMessage";
    return axios.post(url, params, {

    });
};

/****业务续办****/
export const ContinuationService = params => {
    var url = globalconfig.baseurl + "/apipccompany/ContinuationService";
    return axios.post(url, params, {

    });
};

/****企业中心：当前业务  {id：公司ID}****/
export const CompanyServiceList = params => {
    var url = globalconfig.baseurl + "/apipccompany/CompanyServiceList";
    return axios.post(url, params, {

    });
};
export const servernum = params => {
    var url = globalconfig.baseurl + "/apipccompany/servernum";
    return axios.post(url, params, {});
};
/****修改密码  {id：公司ID，oldPwd:旧密码，newPwd：新密码}****/
export const ResetPwd = params => {
    var url = globalconfig.baseurl + "/apipccompany/ResetPwd";
    return axios.post(url, params, {

    });
};
/****办理期限****/
export const getparameterbytype = params => {
    var url = globalconfig.baseurl + "/apipc/getparameterbytype";
    return axios.post(url, params, {

    });
};
/****索要发票****/
export const EditTicket = params => {
    var url = globalconfig.baseurl + "/apipccompany/EditTicket";
    return axios.post(url, params, {

    });
};
/****招聘会点击量录入****/
export const jobfairview = params => {
    var url = globalconfig.baseurl + "/apipc/jobfairview";
    return axios.post(url, params, {

    });
};
/****PC企业中心--业务办理--上传缴费凭证、实收金额、是否已缴费****/
export const subywpayvucher = params => {
    var url = globalconfig.baseurl + "/apipccompany/subywpayvucher";
    return axios.post(url, params, {

    });
};
/********/
export const ispaybycomid = params => {
    var url = globalconfig.baseurl + "/apipccompany/ispaybycomid";
    return axios.post(url, params, {

    });
};
/****更新企业活跃时间****/
export const refreshcompanydate = params => {
    var url = globalconfig.baseurl + "/apipccompany/refreshcompanydate";
    return axios.post(url, params, {

    });
};

/****岗位批量操作****/
export const posistatus = params => {
    var url = globalconfig.baseurl + "/apipccompany/posistatus";
    return axios.post(url, params, {

    });
};

/********/
export const getOperationmanualbynews = params => {
    var url = globalconfig.baseurl + "/apipc/getOperationmanualbynews";
    return axios.post(url, params, {

    });
};
/****最后业务办理时间****/
export const getywstartdate = params => {
    var url = globalconfig.baseurl + "/apipccompany/getywstartdate";
    return axios.post(url, params, {

    });
};
/****招聘会企业岗位详情****/
export const onlineposicompanyjobsdetail = params => {
    var url = globalconfig.baseurl + "/apipc/onlineposicompanyjobsdetail";
    return axios.post(url, params, {

    });
};/****岗位重新启用****/
export const getywenddate = params => {
    var url = globalconfig.baseurl + "/apipccompany/getywenddate";
    return axios.post(url, params, {

    });
};
/****招聘会岗位投递****/
export const sendzphperson = params => {
    var url = globalconfig.baseurl + "/apipcuser/sendzphperson";
    return axios.post(url, params, {

    });
};
/****招聘会简历投递列表****/
export const getzphperjobbycomid = params => {
    var url = globalconfig.baseurl + "/apipc/getzphperjobbycomid";
    return axios.post(url, params, {

    });
};
/****招聘会简历投递最新数量****/
export const notreadzphjobs = params => {
    var url = globalconfig.baseurl + "/apipccompany/notreadzphjobs";
    return axios.post(url, params, {

    });
};
/****获取最大发布岗位数量-已发布的有效招聘岗位数****/
export const getoffernumbers = params => {
    var url = globalconfig.baseurl + "/apipccompany/getoffernumbers";
    return axios.post(url, params, {

    });
};
/****下载简历****/
export const downloadper = params => {
    var url = globalconfig.baseurl + "/apipccompany/downloadper";
    return axios.post(url, params, {

    });
};
export const canceldownuser = params => {
    var url = globalconfig.baseurl + "/apipccompany/canceldownuser";
    return axios.post(url, params, {

    });
};