<template>
  <div class="base-drawer">
    <el-drawer
      :visible.sync="visible"
      :with-header="false"
      :size="size + 'px'"
      :close-on-press-escape="false"
      :wrapperClosable="false"
    >
      <div :class="classname">
        <!-- 按钮区 -->
        <el-row class="baseBtngroup">
          <el-col :span="23" class="baseBtnRow">
            <slot name="header"></slot>
          </el-col>
          <el-col :span="1">
            <div class="closeIcon">
              <el-button type="text">
                <i class="el-icon-close" @click="clickCloseHandle"></i
              ></el-button>
            </div>
          </el-col>
        </el-row>
        <!-- 标题(v-html用于，有些标题上的箱号需要变颜色) -->
        <el-row v-if="title" class="drawerTitle" v-html="title"></el-row>
        <!-- 有些内容需要固定在滚动条之外 -->
        <slot name="fix"></slot>
        <!-- 主体 -->
        <!-- <vue-scroll :ops="scrollOps" :style="{ height: scrollerHeight }"> -->
        <div class="drawerContent" v-if="visible">
          <slot></slot>
        </div>
        <!-- </vue-scroll> -->
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "BaseDrawer",
  props: {
    // 抽屉的显示隐藏
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    // 标题
    title: {
      type: String,
      default: "",
    },
    // 抽屉宽度
    size: {
      type: Number,
      default: 800,
    },
    classname: {
      type: String,
      default: "def_loading",
    },
  },
  data() {
    return {
      scrollOps: {},
    };
  },
  watch: {
    classname(val) {
    },
  },
  computed: {
    scrollerHeight: function () {
      return window.innerHeight - 100 + "px";
    },
  },
  methods: {
    clickCloseHandle() {
      // 触发一个关闭事件,可以用于关闭前的数据重置
      this.$emit("closeDrawer");
      // 关闭抽屉
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style scoped>
/* 抽屉 */
.base-drawer .drawerTitle {
  border-bottom: 2px solid #ee652e;
  font-size: 14px;
  font-weight: 700;
  margin: 20px;
  padding-bottom: 10px;
}
.base-drawer >>> .baseBtnRow > div {
  font-size: 0;
}
.base-drawer >>> .baseBtnRow .el-button {
  /* width: 84px; */
  min-width: 84px;
  height: 32px;
  padding: 0 10px;
  font-size: 12px;
}

.base-drawer >>> .el-col .closeIcon .el-button {
  width: max-content;
  height: auto;
  color: #606266;
}

.base-drawer >>> * {
  font-size: 12px;
}

.base-drawer .drawerContent {
  padding: 0 20px;
}

.base-drawer .closeIcon .iconguanbi2 {
  vertical-align: middle;
  cursor: pointer;
  font-size: 16px;
}

.base-drawer >>> .closeIcon {
  text-align: right;
}

.base-drawer .fixContent {
  padding: 20px 40px;
}

.base-drawer >>> .baseBtngroup {
  padding: 15px 20px;
  display: flex;
  align-items: center;

  border-bottom: 1px solid #ebecf0;
}

.base-drawer1 {
  background-color: #f5f5f5 !important;
}
.base-drawer >>> .el-drawer__open .el-drawer.rtl {
  overflow-y: hidden;
}
.base-drawer >>> .el-drawer__open .el-drawer.rtl::-webkit-scrollbar {
  display: none;
}
.base-drawer >>> .el-drawer__open .el-drawer.rtl .drawerContent {
  height: calc(100vh - 150px);
  overflow-y: scroll;
}
.base-drawer >>> .drawerContent::-webkit-scrollbar {
  display: none;
}
/* .base-drawer>>>.el-drawe .el-drawer__body::-webkit-scrollbar{
  width: 1px;
}
.base-drawer>>>.el-drawe .el-drawer__body .def_loading::-webkit-scrollbar{
  width: 1px;
} */
</style>