<template>
	<div>
		<el-drawer :wrapperClosable="true" title="简历查看" size="800px" :visible.sync="drawer" :direction="direction">
			<div class="jobdetail">
				<div class="jobdetail-content">
					<div class="jobdetail-header">
						<div class="jobdetail-header-left">
							<div class="pic">
								<img :src="info.peR_IMG_PATH? setuserlogo(info.peR_IMG_PATH): require('@/assets/img/tx.png')"
									alt="" />
							</div>
						</div>
						<div class="jobdetail-header-right">
							<div class="name">
								{{info.peR_NAME? this.isCall==1? info.peR_NAME: utils.formatName(info.peR_NAME): "神秘人"}}
							</div>
							<div class="flex1" style="width:100%;">
								<span>{{ info.peR_GENDER}}</span>
								<span>|</span>
								<span>{{info.age}}岁</span>
								<span>|</span>
								<span class="flex1">{{info.peR_WORK_YEARS>0?info.peR_WORK_YEARS+"年":"无"}}工作经验</span>
								<span>|</span>
								<span>{{ info.xueli }}</span>
							</div>
							<div class="flex1" style="margin-top: 10px;font-size: 16px;">
								<span><i class="el-icon-mobile-phone" style="font-size: 24px;margin-right: 9px;"></i>{{
											info.peR_CELLPHONE? this.isCall==1
												? info.peR_CELLPHONE
												: utils.phone(info.peR_CELLPHONE)
											: ""
									}}</span>
								<span style="margin-left:67px;"><i class="el-icon-message"
										style="font-size: 24px;margin-right: 18px;"></i>{{ info.peR_EMAIL? this.isCall==1
												? info.peR_EMAIL
												: utils.phone(info.peR_EMAIL)
											: "" }}</span>
							</div>
						</div>
						<div class="follow-Collection">
							<div class="follow" v-if="this.isCall==0"
								@click="info.isFav != 0 ? cancelfavuser( ) :favuser()"
								:class="{ active: info.isFav != 0 ? true :false  }">
								<i :class="info.isFav != 0 ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
								<span>收藏</span>
							</div>
							<div class="Collection" v-if="this.isCall==0 && info.isDown == 0" @click="downloadper()">
								<i class="el-icon-download"></i>
								<span>下载</span>
							</div>
						</div>
					</div>
					<div class="jobdetail-contain">
						<div class="jobdetail-introduce">
							<div class="introduce-item">
								<div class="introduce-h2">期望职位</div>
								<div class="introduce-text " v-for="item in worklist">
									<div style="width: 100%;">
										<span class="title">{{ item.name }}</span>
										<div class="flex1" style="width: 100%;">
											<p style="width: 50%;">期望薪资：{{setsalary(item.salary, item.salarye)}}</p>
											<p style="width: 50%;">
												期望城市：{{ CodeToText(item.percode) }}/{{ CodeToText(item.citycode) }}/{{ CodeToText(item.areacode) }}
											</p>
										</div>
										<div class="flex1" style="width: 100%;">
											<p style="width: 50%;">工作性质：{{item.nature}}</p>
											<p style="width: 50%;">期望行业：{{ item.hyName }}</p>
										</div>
									</div>
								</div>
							</div>
							<div>
								<div class="introduce-item">
									<div class="introduce-h2">自我评价</div>
									<div class="introduce-text">
										<p v-html="info.peR_INTRODUCE"></p>
									</div>
								</div>
								<div class="introduce-item">
									<div class="introduce-h2">职业技能</div>
									<div class="introduce-text">
										<p v-html="info.peR_JNTC_INTRODUCE"></p>
									</div>
								</div>
								<div class="introduce-item">
									<div class="introduce-h2">工作经历</div>
									<div class="introduce-text" v-for="item in infolist1">
										<div class=" flex4">
											<span class="title">{{ item.title }}</span>
											<div>
												{{utils.timeFormat(item.stateTime,3) }}-{{ utils.timeFormat(item.endTime,3) }}
											</div>
										</div>
										<div class="name">{{ item.name }}</div>
										<p v-html="return2Br(item.infoDes)"></p>
									</div>
								</div>
								<div class="introduce-item" v-if="infolist3.length>0">
									<div class="introduce-h2">项目经历</div>
									<div class="introduce-text" v-for="item in infolist3">
										<div class=" flex4">
											<span class="title">{{ item.title }}</span>
											<div>
												{{utils.timeFormat(item.stateTime,3) }}-{{ utils.timeFormat(item.endTime,3) }}
											</div>
										</div>
										<div class="name">{{ item.name }}</div>
										<p v-html="return2Br(item.infoDes)"></p>
									</div>
								</div>
								<div class="introduce-item">
									<div class="introduce-h2">教育经历</div>
									<div class="introduce-text flex4" v-for="item in infolist2">
										<div>
											<span class="title">{{ item.title }}</span>
											<div class="name">
												<span>{{ item.name }}</span>
												<span>|</span>
												<span>{{ item.infoDes }}</span>
											</div>
										</div>
										<div>{{utils.timeFormat(item.endTime,3)}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-drawer>
		<el-dialog title="温馨提示" :visible.sync="centerDialogVisible" width="25%" center>
			<div style="width: 100%; text-align: center">
				<span v-if="ispaybycomiddata == 2">您当前未办理网络招聘业务，请前往办理?</span>
				<span v-if="ispaybycomiddata == 3">您当前办理的业务未完成支付，暂不允许该操作，请前往缴费?</span>
				<span v-if="ispaybycomiddata == 4">您当前办理的业务已过期，请前往重新办理?</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" class="footer-btn" @click="YWBLbind">确 定</el-button>
				<el-button class="footer-btn" @click="centerDialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getresumebyid,
		favuser,
		cancelfavuser,
		ispaybycomid,
		downloadper,
	} from "@/api/company";
	import {
		getinfolist,
		getworklist,
	} from "@/api/user";
	import {
		isnull,
		handleCofirm,
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import globalconfig from "@/assets/js/globalconfig";
	import {
		CodeToText
	} from "element-china-area-data";
	export default {
		props: {
			uid: {
				type: Number,
			},
			isRshow: {
				type: Boolean,
			},
			isCall: {
				type: Number,
			}
		},
		data() {
			return {
				defimgurl: globalconfig.baseimgurl,
				drawer: false,
				direction: "rtl",
				worklist: [],
				infolist1: [],
				infolist2: [],
				infolist3: [],
				info: {},
				pid: "",
				zwid: "",
				iscss: false,
				ispaybycomiddata: 0,
				jobinfo: {},
				userinfo: "",
				idcompanylogin: false,
				centerDialogVisible: false,
			};
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			if (this.userinfo && this.userinfo.coM_ID && !this.zph) {
				this.ispaybycomid();
				this.idcompanylogin = true;
			}
		},
		watch: {
			isRshow() {
				this.drawer = true;
				this.getresumebyid();
				this.infolist()
			},
		},
		mounted() {},
		methods: {
			YWBLbind() {
				this.drawer = false;
				this.centerDialogVisible = false;
				this.$parent.navindex = 9;
				localStorage.setItem("navindex", 9);
				this.$router.push("/companycenter");
			},
			CodeToText(value) {
				return CodeToText[value];
			},
			setuserlogo(url) {
				var data = isnull(url) ? 0 : url.indexOf("http");
				return isnull(url) ?
					require("@/assets/img/logos.png") :
					data < 0 ?
					this.defimgurl + url :
					url;
			},
			infolist() {
				this.getworklist();
				this.getinfolist(1);
				this.getinfolist(2);
				this.getinfolist(3);
			},
			getworklist() {
				var _this = this;
				var par = {
					id: _this.uid,
				};
				getworklist(par).then((res) => {
					if (res.success) {
						_this.worklist = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			getinfolist(cid) {
				var _this = this;
				var par = {
					id: _this.uid,
					cid: cid,
				};
				getinfolist(par).then((res) => {
					if (res.success) {
						if (cid == 1)
							_this.infolist1 = res.data;
						if (cid == 2)
							_this.infolist2 = res.data;
						if (cid == 3)
							_this.infolist3 = res.data;
					} else {
						//_this.$message.error(res.msg);
					}
				});
			},
			return2Br(str) {
				return str.replace(/\r?\n/g, "<br />");
			},
			stringToDates(data) {
				return utils.stringToDate(data);
			},
			ispaybycomid() {
				var _this = this;
				if (!this.userinfo && !this.userinfo.coM_ID) {
					ispaybycomid({
						id: this.userinfo.coM_ID
					}).then((res) => {
						_this.ispaybycomiddata = res.data;
					});
				}
			},
			setsalary(a, b) {
				var data = [];
				if (!isnull(a)) {
					if (a < 100 && a > 0) {
						data.push(a + "k");
					} else if (a >= 1000) {
						data.push(a / 1000 + "k");
					} else {
						data.push(a);
					}
				}
				if (!isnull(b)) {
					if (b < 100 && b > 0) {
						data.push(b + "k");
					} else if (b >= 1000) {
						data.push(b / 1000 + "k");
					} else {
						data.push(b);
					}
				}
				if (data.length === 0) {
					return "面议";
				} else {
					return data.join("-");
				}
			},
			getresumebyid() {
				var _this = this;
				if (isnull(_this.uid) || _this.uid == 0) {
					return false;
				}
				let comid = 0;
				if (this.zph || (this.ispaybycomiddata == 0 && this.idcompanylogin)) {
					comid = this.userinfo.coM_ID;
				}
				var per = {
					id: _this.uid,
					uid: comid,
					zid: _this.zwid,
				};
				if (this.zph) {
					per.code = "1";
				}
				getresumebyid(per).then((res) => {
					if (res.success) {
						_this.info = res.data[0];
							if (_this.info.isDown > 0 || _this.info.isJobs > 0) _this.isCall == 1;
						_this.info.peR_INTRODUCE = _this.info.peR_INTRODUCE ? this.return2Br(_this.info
							.peR_INTRODUCE) : "暂未留下更多自我评价";
						_this.info.peR_JNTC_INTRODUCE = _this.info.peR_JNTC_INTRODUCE ? this.return2Br(_this.info
							.peR_JNTC_INTRODUCE) : "暂未留下更多职业技能";
						_this.info.peR_WORK_INTRODUCE = _this.info.peR_WORK_INTRODUCE ? this.return2Br(_this.info
							.peR_WORK_INTRODUCE) : "暂未留下更多工作经历";
						_this.info.age = this.getAge(_this.info.peR_BIRTHDAY)
						res.data[0].hy = res.data[0].peR_LASTLOAD ?
							this.utils.handleDateHY(res.data[0].peR_LASTLOAD) :
							"";
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			Required() {
				var userinfo = null;
				let userinfoStr = localStorage.getItem("userinfo");
				if (userinfoStr != null && userinfoStr != '') {
					userinfo = JSON.parse(userinfoStr)
				}
				if (!userinfo || !userinfo.coM_ID) {
					this.resolvewin("/loginc", {
						type: 1,
						isback: 1
					}, 1);
					return true;
				} else {
					return false;
				}
			},
			//下载简历
			downloadper() {
				if (this.Required()) {
					return;
				}
				if (this.zph || (this.ispaybycomiddata == 0 && this.idcompanylogin)) {
					var _this = this;
					downloadper({
						id: this.userinfo.coM_ID,
						uid: _this.uid,
					}).then((res) => {
						if (res.success) {
							this.getresumebyid();
							_this.$message.success("简历已下载!");
						} else {
							_this.$message.error(res.msg);
						}
					});
				} else {
					this.centerDialogVisible = true;
				}
			},
			//人才收藏
			favuser() {
				if (this.Required()) {
					return;
				}
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					uid: _this.uid,
				};
				favuser(par).then((res) => {
					if (res.success) {
						this.getresumebyid();
						_this.$message.success("简历已收藏!");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//计算年龄
			getAge(strAge) {
				var birArr = strAge.split("-");
				var birYear = birArr[0];
				var birMonth = birArr[1];
				var birDay = birArr[2];

				d = new Date();
				var nowYear = d.getFullYear();
				var nowMonth = d.getMonth() + 1; //记得加1
				var nowDay = d.getDate();
				var returnAge;

				if (birArr == null) {
					return false
				};
				var d = new Date(birYear, birMonth - 1, birDay);
				if (d.getFullYear() == birYear && (d.getMonth() + 1) == birMonth && d.getDate() == birDay) {
					if (nowYear == birYear) {
						returnAge = 0; // 
					} else {
						var ageDiff = nowYear - birYear; // 
						if (ageDiff > 0) {
							if (nowMonth == birMonth) {
								var dayDiff = nowDay - birDay; // 
								if (dayDiff < 0) {
									returnAge = ageDiff - 1;
								} else {
									returnAge = ageDiff;
								}
							} else {
								var monthDiff = nowMonth - birMonth; // 
								if (monthDiff < 0) {
									returnAge = ageDiff - 1;
								} else {
									returnAge = ageDiff;
								}
							}
						} else {
							return "出生日期晚于今天，数据有误"; //返回-1 表示出生日期输入错误 晚于今天
						}
					}
					return returnAge;
				} else {
					return ("输入的日期格式错误！");
				}
			},
			//取消人才收藏
			cancelfavuser() {
				var _this = this;
				handleCofirm('确认取消收藏该简历吗？', 'warning', ).then(res => {
					var par = {
						id: this.userinfo.coM_ID,
						uid: _this.uid,
					};
					cancelfavuser(par).then((res) => {
						if (res.success) {
							this.getresumebyid();
							_this.$message.success("已取消收藏!");
						} else {
							_this.$message.error(res.msg);
						}
					});
				}).catch(err => {
					//console.log('err', err) // cancel
				})
			},

			fl(data) {
				return data.split(",");
			},
			Complete() {
				location.reload();
			},
		},
	};
</script>
<style lang="scss" scoped>
	@media print {
		body {
			-webkit-print-color-adjust: exact;
			-moz-print-color-adjust: exact;
			-ms-print-color-adjust: exact;
			print-color-adjust: exact;
		}
	}

	.jobdetail {
		display: flex;
		flex-direction: column;
		height: 100%;
		border: 1px solid #eee;
	}

	.jobdetail-content {
		flex: 1;
		position: relative;
		width: 100%;
		margin: 0px auto;
		padding: 20px 0;
		background: url(../../assets/img/bgs.png) repeat #FFF;


		.jobdetail-header {
			// padding: 24px;
			// margin-bottom: 12px;
			position: relative;
			display: flex;
			align-content: center;
			// align-items: stretch;
			margin: 0 auto;

			.jobdetail-header-left {
				.pic {
					width: 85px;
					height: 85px;
					border-radius: 50%;
					overflow: hidden;
					margin-right: 20px;
					margin-left: 55px;

					img {
						width: 100%;
					}
				}


				.jobdetail-header-title {
					line-height: 32px;
					font-size: 18px;
					color: #333;
					display: flex;
					justify-content: space-between;
					margin-bottom: 16px;

					.job-name {
						font-weight: bold;
					}

					.job-salary {
						font-weight: bold;
						color: #ff7630;
					}
				}

				.jobdetail-header-view {
					display: flex;
					align-items: center;
					margin-bottom: 12px;

					.jobdetail-header-item {
						width: 225px;
						text-align: left;
						font-size: 14px;
						color: #898989;
						display: flex;

						span {
							color: #666;
						}
					}

					.jobdetail-header-welfare {
						font-size: 14px;
						color: #898989;
						display: flex;
						flex-wrap: wrap;

						span {
							padding: 4px 8px;
							font-size: 12px;
							color: #66b1ff;
							background: #eee;
							border-radius: 2px;
							margin-right: 12px;
						}
					}
				}

				.jobdetail-header-view:last-child {
					margin-bottom: 0;
				}
			}

			.jobdetail-header-right {
				flex: 1;
				width: 100%;

				// text-align: right;
				// display: flex;
				// flex-direction: column;
				.name {
					color: #101010;
					font-size: 20px;
					text-align: left;
					font-family: SourceHanSansSC-black;
					font-weight: bold;
					margin-bottom: 10px;
				}

				span {
					margin-right: 10px;
				}

				i {
					font-size: 24px;
				}

				.release-time {
					width: 100%;
					font-size: 14px;
					color: #787878;
					margin-bottom: 12px;
					height: 26px;
					line-height: 26px;
				}

			}

			.follow-Collection {
				top: -20px;
				left: -1px;
				position: absolute;

				.follow,
				.Collection {
					color: #0089FF;
					cursor: pointer;
					border: 2px solid #0089FF;
					text-align: center;
					i {height: 30px;width: 50px;line-height: 30px;
						font-size: 22px;
					}
					span{display: block;font-size: 12px;line-height: 20px;}
				}

				.Collection {
					color: #fff;
					background-color: #0089FF;
				}

				.active {
					color: #66b1ff;
					border: 1px solid #66b1ff;
				}
			}

			.apply-btn {
				width: 100%;
				align-self: flex-end;
				text-align: right;
			}
		}

		.introduce-text {

			.down-Collection {
				//width: 100%;
				//display: flex;
				//justify-content: flex-end;
				margin-top: 10px;

				.down {
					display: flex;
					align-items: center;
					justify-content: center;
					color: #66b1ff;
					cursor: pointer;
					border: 1px solid #66b1ff;
					width: 140px;
					height: 40px;
					border-radius: 8px;

					i {
						font-size: 22px;
						margin-right: 8px;
					}
				}
			}
		}

		.jobdetail-contain {
			margin: 0 auto;
			display: flex;
			align-items: stretch;
			position: relative;

			.jobdetail-introduce {
				flex: 1;
				width: 100%;
				padding: 0 55px;
				margin-top: 20px;

				.introduce-item {
					position: relative;
					padding-bottom: 16px;

					.introduce-h2 {
						padding-left: 24px;
						position: relative;
						color: rgba(16, 16, 16, 100);
						font-size: 14px;
						text-align: left;
						margin-bottom: 12px;
					}

					.introduce-h2::before {
						width: 3px;
						height: 18px;
						left: 0;
						top: 3px;
						border-radius: 2.5px;
						background: #0096FF;
						content: "";
						position: absolute;
					}

					.introduce-text {
						position: relative;
						text-align: left;
						font-size: 12px;
						color: #898989;
						margin: 34px 0;
						margin-top: 10px;
						color: #101010;

						h2 {
							font-size: 12px;
							font-weight: normal;
						}

						.title {
							font-size: 13px;
							font-weight: bold;
						}

						.name {
							margin-top: 10px;
							margin-bottom: 10px;
							font-size: 14px;
							color: #101010;

							span {
								margin-right: 10px;
							}
						}

						p {
							color: #898989;
							margin: 0px;
							padding: 0px;
							line-height: 26px;
						}

						img {
							width: 100%;
						}
					}
				}

				.introduce-item:first-child {
					border-top: none;
				}
			}
		}
	}
</style>
