<template>
	<div class="footer">
		<div class="footer-contain">
			<div class="footer-content">
				<el-row>
					<el-col :span="14">
						<div class="footer-text">
							<!-- <img class="footlogo" src="@/assets/img/footlogo.png" alt="" /> -->
							<img class="tel" src="@/assets/img/telw.png" alt="" />
							<span class="text">联系电话：0579-89010200</span>
							<span style="font-size: 10px;">未经书面授权严禁转载本站任何信息</span>
						</div>
						<div class="footer-text mt">
							<span style="font-size: 18px">档案查询：0579-88892820</span>
						</div>
						<div class="footer-text mt">
							<!-- <span>兰溪市安程人力资源有限公司</span>
							<span>版权所有</span> -->
							<span>Copyright © lxrcsc.net</span>
						</div>
						<div class="footer-text mt">
							<span>地址：浙江省兰溪市兰江街道振兴路508号C座</span>
							<span><a style="color: #898989;" href="https://beian.miit.gov.cn"
									target="_blank">浙ICP备2022015008号-1</a></span>
						</div>
						<div class="footer-text mt">
							<img src="@/assets/img/ba.png" alt="" /><span>浙公网安备
								3307810210013号</span><span>网站标识码：3307810037</span>
						</div>
					</el-col>
					<el-col :span="10">
						<div class="footer-net">
							<div class="footer-net-list">
								<el-image fit="cover" class="img" :src="require('@/assets/img/code1.jpg')"
									:preview-src-list="[require('@/assets/img/code1.jpg')]" alt=""></el-image>
								<div class="net-name">微信公众号</div>
							</div>
							<div class="footer-net-list">
								<el-image fit="cover" class="img" :src="require('@/assets/img/code2.jpg')"
									:preview-src-list="[require('@/assets/img/code2.jpg')]" alt=""></el-image>
								<div class="net-name">微信小程序</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		GetAdvLinkByType,
		GetTextLinkList
	} from "@/api/home";
	export default {
		data() {
			return {
				selectData: [],
			};
		},
		created() {
			this.GetTextLinkList();
		},
		methods: {
			GetTextLinkList(type) {
				var _this = this;
				var par = {
					id: type,
				};
				GetTextLinkList(par).then((res) => {
					if (res.success) {
						var rdata = res.data;
						_this.selectData = rdata;
						rdata.forEach((item, i) => {
							item.value = "";
							this.GetAdvLinkByType(item.id, i);
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			GetAdvLinkByType(type, i) {
				var _this = this;
				var par = {
					id: type,
				};
				GetAdvLinkByType(par).then((res) => {
					if (res.success) {
						_this.selectData[i].options = res.data;
						_this.selectData = JSON.parse(JSON.stringify(_this.selectData));
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			changesel(v) {
				this.resolvewin(v, {}, 6);
			},
		},
	};
</script>


<style lang="scss" scoped>
	.footer {
		width: 100%;
		margin: 0px;
		padding: 10px 0;
		background: #333;
		position: relative;

		.footer-contain {
			width: 1200px;
			margin: 0px auto;

			.mt {
				margin-top: 2px;

				span {
					color: #898989;
				}

			}

			.mt a:link,
			.mt a:visited {
				color: #898989;

			}

			.footer-content {
				// padding-top: 20px;

				.footer-text {
					font-size: 14px;
					color: #fff;
					text-align: left;
					display: flex;
					align-items: center;
					line-height: 25px;

					.footlogo {
						height: 40px;
						margin-right: 10px;
					}

					.tel {
						width: 40px;
						height: 40px;
						margin-right: 10px;
					}

					img {
						margin-right: 4px;
					}

					.text {
						font-size: 20px;
						font-weight: bold;
					}

					span {
						margin-right: 14px;

						a {
							color: #fff;
							text-decoration: none;
						}

						a:hover {
							color: #0055ff;
							text-decoration: underline
						}
					}
				}

				.footer-net {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					height: 148px;

					.footer-net-list {
						display: flex;
						flex-direction: column;
						text-align: center;
						margin-left: 12px;
						color: #fff;
						font-size: 14px;

						.net-name {
							color: #898989;
						}

						.img {
							width: 98px;
							height: 98px;
							margin-bottom: 5px;
							background: #ffffff;
							padding: 4px;
							border-radius: 4px;
						}

					}
				}
			}
		}
	}
</style>